<template>
  <div>
    <div class="header flex flex_alicen flex_juspabt">
      <div @click="clickchange(-1)" class="pading25">
        <img class="padingicons" src="./../../assets/kuaitui.png" alt="" srcset="">
        <div>同步</div>
      </div>
      <div>已上课 {{ playTime }}</div>
      <div @click="clickchange(1)" class="pading25">
        <div>同步</div>
        <img class="padingicons" src="./../../assets/kuaijin.png" alt="" srcset="">
      </div>
    </div>

    <div class="masktan" v-if="$store.state.maskshow!=''">
      <div class="masktan_centes">
        <div class="masktan_titles">提示</div>
        <div class="masktan_contens">{{$store.state.maskshow}}</div>
        <div class="masktan_buttons">
          <div @click="fails" class="masktan_buttes masktan_quxiao">取消</div>
          <div @click='succers' class="masktan_buttes masktan_queren">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playTime:{//当前播放的时间
      type:String,
      default: () => '00:00',
    },
  },
  data() {
    return {
    };
  },
  methods:{
    fails(){//点击取消
      this.$store.commit("setStartPlays",false);
      this.$store.commit("setMaskshow",'')
    },
    succers(){// 点击确认
      this.$store.commit("setStartPlays",false);
      this.$store.commit("setMaskshow",'')
      location.reload()
    },
    clickchange(res){//点击同步 学生加1s 或者减1s
      this.$store.commit("setplayTime",res);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  background: #754fb2;
  color: #fff;z-index: 10;
}
.pading25{
  margin: 0 25px;
  font-size: 12px;
  line-height: 24px;
  border: 1px solid #fff;
  padding:0 8px;
  border-radius: 4px;
  cursor: pointer;
  display:flex;
  align-items: center;
}
.padingicons{
  width: 16px;
  margin: 0 5px;
}


.masktan{
  position: fixed;top: 0;left: 0;right: 0;bottom: 0;
  z-index: 9999;background: rgba(0, 0, 0, 0.5);
  display: flex;align-items:center;justify-content: center;
}
.masktan_centes{
  width: 64%;border-radius: 4px;
  overflow: hidden;background:#fff;
  text-align: center;
  padding:15px
}
.masktan_titles{
  padding:15px 0 20px
}
.masktan_contens{
  padding: 10px;
}
.masktan_buttons{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:15px 10px 0;
}
.masktan_buttes{
  cursor: pointer;
  padding:5px 15px;
}
</style>