<template>
  <div class="icons flex_cen_cen" v-if="keys">
      <img class="icon_centes" :src="jsons[keys]" alt="" srcset="">
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed:{
    ...mapState({
      keys:state => state.keys
    })
  },
  data() {
    return {
        jsons:{
            'error':require("../../assets/expression/error.gif"),
            'counter':require("../../assets/expression/counter.gif"),
            'like':require("../../assets/expression/like.gif"),
            'star':require("../../assets/expression/star.gif"),
            'trophy':require("../../assets/expression/trophy.gif"),
        }
    };
  },
}
</script>

<style>
.icons{
  position: fixed;top: 0;left: 0;right: 0;bottom: 0;
  z-index: 1000;
}
.icon_centes{
    width: 30vh;
}

</style>