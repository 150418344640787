<template>
  <div class="video_player flex_cen_cen">
    <div v-if="isiOS" class="video_player flex_cen_cen">
      <video webkit-playsinline preload="auto"
        playsinline ref="myvideo" muted
        x5-video-player-type="h5"
        x5-video-player-fullscreen='h5'
        x5-playsinline :class="ispalay?'':'vidoes2'"
        x-webkit-airplay
        webview.allowsInlineMediaPlayback="YES"
        x5-video-orientation="portraint" poster='../../assets/default.png' :src="src" :style="viewSize" ></video>
      <img  v-if="!ispalay" class="images"  src="../../assets/default.png" alt="" srcset="">
      <audio  preload="auto" ref="myAudio"  :src="src" ></audio>
    </div>
    <div v-else class="video_player flex_cen_cen">
      <video webkit-playsinline preload="auto"
        playsinline ref="myvideo"
        x5-video-player-type="h5"
        x5-video-player-fullscreen='h5'
        x5-playsinline :class="ispalay?'':'vidoes2'"
        x-webkit-airplay
        webview.allowsInlineMediaPlayback="YES"
        x5-video-orientation="portraint" poster='../../assets/default.png' :src="src" :style="viewSize" ></video>
      <img  v-if="!ispalay" class="images"  src="../../assets/default.png" alt="" srcset="">
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoView",
  props: {
    // 播放路径
    src: {
      type: String,
      default: "",
    },
    // 学生 老师 白板的唯一值
    ids: {
      type: String,
      default: "",
    },
    // 播放端口的大小
    viewSize: {
      type: Object,
      default: () => {
        return {
          width: "184px",
          height: "321px",
        };
      },
    },
    // 一些主要信息的传值
    items:{
      type:Object
    }
  },
  watch:{
    // 点击快进到那个时间点
    "$store.state.changeTime":function(){
      // 判断是不是学生
      if(this.items.video_type==1){
        if((this.$store.state.changeTime.toFixed(0))<(this.items.enterAt+this.$store.state.delayTime)){
          this.stopPlays()
          return
        }
        if((this.$store.state.changeTime.toFixed(0))>(this.items.enterAt+this.$store.state.delayTime)){
          this.statrplay()
        }
        // 判断当前时间点  学生是否离开课堂
        if((this.$store.state.changeTime.toFixed(0))>(this.items.enterAt+this.items.video_duration+this.$store.state.delayTime)){
          this.stopPlays()
          return
        }
        this.kuaijin(this.$store.state.changeTime,this.items.enterAt+this.$store.state.delayTime)
      }else{
        this.kuaijin(this.$store.state.changeTime,0)
      }
    },
    // 监听时间的变化
    "$store.state.currTime":function(){
      // 判断是不是学生
      if(this.items.video_type==1){
        console.log(this.items.enterAt,this.$store.state.delayTime)
      // 判断当前时间点 学生是否已经进入课堂
        if((this.$store.state.currTime.toFixed(0)*1000)<(this.items.enterAt+this.$store.state.delayTime)){
          this.stopPlays()
          return
        }
        if((this.$store.state.currTime.toFixed(0)*1000)>(this.items.enterAt+this.$store.state.delayTime)){
          this.statrplay()
        }
        // 判断当前时间点  学生是否离开课堂
        if((this.$store.state.currTime.toFixed(0)*1000)>(this.items.enterAt+this.items.video_duration)){
          this.stopPlays()
          return
        }
        this.chengtimes((this.$store.state.currTime.toFixed(0)*1000),this.items.enterAt+this.$store.state.delayTime)
      }
    },
    // 监听倍速的变化
    "$store.state.speed":function(){
      this.beisu()
    },
    // 监听开始播放与停止播放
    "$store.state.startPlays":function(){
        this.statrplay()
        if(this.items.video_type==1&&this.items.enterAt>0){
          this.stopPlays()
          return;
        }
    }
  },
  data() {
    return {
      isiOS:false,// 判断是否是ios
      ispalay:false,//是否进行播放
    };
  },
  created(){
      var versio=this.versions()
      if(versio.ios||versio.iPhone||versio.iPad||versio.mac){
        this.isiOS=true
      }
  },
  mounted(){
    this.$refs.myvideo.addEventListener("timeupdate",this.chengse)
  },
  methods:{
    // 监听播放时间的变化 来进行 随时变化时间
    chengse(){
      if(this.ids=='teacher'){
        this.$store.commit("setCurrcTime",this.$refs.myvideo.currentTime);
      }
      if(this.isiOS){
        if(Math.abs(this.$refs.myAudio.currentTime-this.$refs.myvideo.currentTime)>1000){
          this.$refs.myAudio.currentTime = this.$refs.myvideo.currentTime
        }
      }
    },
    // 监听时间的变化 然后实时变化
    chengtimes(currTime,startTise){
      if(currTime==startTise&&!this.ispalay){
        this.statrplay()
        return;
      }
      if((currTime-((this.$refs.myvideo.currentTime.toFixed(0)*1000)+startTise))>1000){
        this.$refs.myvideo.currentTime = ((currTime-startTise)/1000).toFixed(0)
        if(this.isiOS){
          this.$refs.myAudio.currentTime = ((currTime-startTise)/1000).toFixed(0)
        }
      }
    },
    // 点击开始播放
    statrplay(){
      if(!this.ispalay){
        this.ispalay=true
        if(this.isiOS){
          this.$refs.myAudio.currentTime = 0
          this.$refs.myAudio.play()
        }
        this.$refs.myvideo.currentTime = 0
        this.$refs.myvideo.play()
      }
    },
    // 点击时间进行快进
    kuaijin(times,startTise){
      if((times-startTise)>=0){
        this.ispalay=true
        if(this.isiOS){
          this.$refs.myAudio.currentTime = ((times-startTise)/1000).toFixed(0)
          this.$refs.myAudio.play()
        }
        this.$refs.myvideo.currentTime = ((times-startTise)/1000).toFixed(0)
        this.$refs.myvideo.play()
      }else{
        this.stopPlays()
      }
    },
    // 点击停止播放
    stopPlays(){
      if(this.ispalay){
        this.ispalay=false
        if(this.isiOS){
          this.$refs.myAudio.pause()
        }
        this.$refs.myvideo.pause()
      }
    },
    // 根据倍速来设置当前播放器的倍速
    beisu(){
      if(this.isiOS){
      this.$refs.myAudio.playbackRate=this.$store.state.speed
      }
      this.$refs.myvideo.playbackRate=this.$store.state.speed
    },
    // 区分浏览器的版本
    versions:function(){
        var u = navigator.userAgent;
        return {
            trident: u.indexOf('Trident') > -1, //IE内核
            presto: u.indexOf('Presto') > -1, //opera内核
            webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
            gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,//火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, //android终端
            iPhone: u.indexOf('iPhone') > -1 , //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf('iPad') > -1, //是否iPad
            webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
            mac: !!u.match('Mac OS X'), //是否mac （2015-01-22新增）
        };
    }
  }
};
</script>

<style>
.images{
  width: 100%;height: 100%;
}
.myvideo{
  width: 100%;height: 100%;
}
.video_player{
  width: 100%;height: 100%;
}
.vidoes2{
  width: 0 !important;
  height: 0 !important;
}
.masktan{
  position: fixed;top: 0;left: 0;right: 0;bottom: 0;
  z-index: 9999;background: rgba(0, 0, 0, 0.5);
  display: flex;align-items:center;justify-content: center;
}
.masktan_centes{
  width: 64%;border-radius: 4px;
  overflow: hidden;background:#fff;
  text-align: center;
  padding:15px
}
.masktan_titles{
  padding:15px 0 20px
}
.masktan_contens{
  padding: 10px;
}
.masktan_buttons{
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding:15px 30px 0;
}
.masktan_buttes{
cursor: pointer;
}
</style>