import Vue from 'vue'
import App from './App.vue'
import store from './vuex'
import router from './router.js'
import {request} from './utils/axios.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
Vue.use(ElementUI)


import VConsole from 'vconsole'

if(process.env.VUE_APP_KEY=='dev'){
  const vConsole = new VConsole()
  Vue.use(vConsole)
  // vConsole.destroy();
  //显示 vConsole 的开关按钮。
  // vConsole.showSwitch()
}

Vue.config.productionTip = false
Vue.prototype.request=request
// Vue.prototype.$video=Video

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')
