<template>
  <div class="bottom-list flex flex_juscen flex_aliend">
    <div v-for="(student, index) in students" :key="index"  @click="pictsusers(index)" class="item_view backage flex" :class="{chese:pitchStudent==index,samlls:openWrite}">
      <div v-if="pitchStudent!=index" class="item_lefts">
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../assets/icons/icons1.png" alt="" srcset="">
          <div>15</div>
        </div>
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../assets/icons/icons2.png" alt="" srcset="">
          <div>15</div>
        </div>
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../assets/icons/icons3.png" alt="" srcset="">
          <div>15</div>
        </div>
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../assets/icons/icons4.png" alt="" srcset="">
          <div>15</div>
        </div>
      </div>
      <div class="item_user">
        <VideoView :ids="'student'+index" :items='student' :src="student.path" :viewSize="{width:pitchStudent!=index||openWrite?'100%':screen?'75vw':'56.25vh'}" />
      </div>
    </div>
    <div @click="pictsusers(nums)" class="item_view backage flex" :class="{chese:pitchStudent==nums,samlls:openWrite}">
      <div v-if="pitchStudent!=nums" class="item_lefts"></div>
      <div class="item_user">
        <VideoView :ids="'teacher'" :items='master' :src="master.path" :viewSize="{width:pitchStudent!=nums||openWrite?'100%':screen?'75vw':'56.25vh'}" />
      </div>
    </div>
    <div  class="item_view backage flex" v-for="items in unmanned" :key="items">
        <div class="item_lefts"></div>
        <div class="item_user">
          <img  class="imgse" src="../../assets/default.png" alt="" srcset="">
        </div>
    </div>
  </div>
</template>

<script>
import VideoView from "@/components/Players/VideoView";
import { mapState } from "vuex";
export default {
  name: "BottomList",
  components: {
    VideoView,
  },
  computed:{
    ...mapState({
      pitchStudent:state => state.pitchStudent,
      unmanned:state => state.unmanned,
      students:state => state.students,
      master:state => state.master,
      screen:state => state.screen,
      openWrite:state => state.openWrite,
    }),
  },
  data() {
    return {
      nums:4
    };
  },
  methods:{
    pictsusers(ins){//关闭白板  点击老师或者学生
      this.$store.commit("setPitchStudent",ins);
    },
    setchange(){//是否开启白板  点击打开白板和关闭白板
      this.$store.commit("setBroadView");
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-list {
  width: 100%;height: 100%;
}
.item_view{
  margin: 0 5px;height: 100%;padding: 3px;
  border-radius: 4px;width: 20%;overflow: hidden;
}

.item_user{
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.chese{
  position: absolute;
  top: 0;padding: 0;
  z-index: 60;overflow: hidden;
  height: calc(76vh - 50px);
  display: flex;align-items: center;justify-content: center;
}
.chese.samlls{
  margin-left: 5px;
  height: 18.6vh;
}
.imgse{
  width: 100%;height: 100%;
}
</style>