<template>
  <div class="slider " :class="{'matsers': !openWrite}">
    <div @click="pictsusers(4)" :class="{matserflex:pitchStudent!=4}" class="heigsr">
        <VideoView :src="master.path" :viewSize="{width: openWrite||pitchStudent!=4?'100%':screen?'75vw':'56.25vh'}" />
    </div>
    <div @click="pictsusers(0)" :class="{matserflex: pitchStudent!=0}" class="heigsr">
        <VideoView :src="students[0].path" :viewSize="{width: openWrite||pitchStudent!=0?'100%':screen?'75vw':'56.25vh'}" />
    </div>
  </div>
</template>

<script>
import VideoView from "@/components/Players/VideoView";
import { mapState } from "vuex";
export default {
  name: "Slider",
  components: {
    VideoView,
  },
  computed:{
    ...mapState({
      screen:state => state.screen,
      openWrite:state => state.openWrite,
      master:state => state.master,
      students:state => state.students,
      pitchStudent:state => state.pitchStudent,
    })
  },
  data() {
    return {
    };
  },
  methods:{
    pictsusers(ins){ // 点击展示老师还是学生
      this.$store.commit("setPitchStudent",ins);
    },
  }
};
</script>

<style lang="scss" scoped>
.slider {
  margin-left: 5px;
  transition: 0.5s height, 0.5s width;
}
.slider.matsers{
  margin:0;position: relative;
  width: 100%;height: 100%;
  display: flex;align-items: center;justify-content: center;
}
.heigsr{
    height: 18.6vh;overflow: hidden;margin-bottom: 5px;
}
.matsers .heigsr{
    height: 100%;
    margin: 0;
}
.matsers .matserflex{
  height: 18.6vh;position: absolute;top: 0;right: 0;
  z-index: 200;
}
</style>