<template>
  <div ref="appds" v-loading="loading" id="app" :class="{'hangpin':!isexhibition,'shupin':isexhibition}">
    <div v-if="!$store.state.startPlays" @click="startplay" class="starPlay flex_cen_cen">
      <img class="starPlay_imgs" src="./assets/videos/playz.png" alt="" srcset="">
    </div>
    <Header :playTime="playTime" ref="header" />
    <div class="centes">
      <router-view></router-view>
    </div>
    <Footer ref="footer" />
    <expression></expression>
    <Progress @kuaijin='kuaijin' @changTime='changTime' :proportion='proportion' :totalTime='totalTime' :playTime="playTime"></Progress>
  </div>
</template>

<script>
var ins=0
import { formatDurationTime,formatFigureTime } from "@/utils/index.js";
import Header from "@/components/Layout/Header";
import Footer from "@/components/Layout/Footer";
import expression from "@/components/Layout/expression";
import Progress from "@/components/Players/Progress";
export default{
  components: {
    Header,
    Footer,
    expression,
    Progress
  },
  watch:{
    "$store.state.currTime":function(){
        this.setTimese((this.$store.state.currTime.toFixed(0))*1000)
    }
  },
  data() {
    return {
      isexhibition:false,
      loading:true,
      playTime:"00:00",
      totalTime:"00:00",
      allTime:0,
      proportion:'0',
      jsons:{},
      startTime:0,
      monitors:0,
      isplay:false
    };
  },
  created(){
    var hrefs=window.location.href
    if(document.body.clientWidth<document.body.clientHeight){
      this.isexhibition=true
      this.$store.commit("setScreen",true);
    }else{
      this.isexhibition=false
      this.$store.commit("setScreen",false);
    }
    if(hrefs.indexOf("?")!=''){
      var arr = hrefs.split("?")[1].split("&")
      var jsons={}
      for(var i=0;i<arr.length;i++){
        var list=arr[i].split("=")
        jsons[list[0]]=list[1]
      }
      this.getdata(jsons)
    }
  },
  methods:{
    getdata(jsons){
      this.loading=true
      this.request('api/site/replay/lesson',{methods:"get",params:jsons}).then(res=>{
        var studys=[]
        if(res.data.videoList.students.length<4){
          for(var i=0;i<4-res.data.videoList.students.length;i++){
            studys.push('asfasf'+i+"safaege")
          }
        }
        var jsonps={
          broad : res.data.videoList.broad,
          master : res.data.videoList.teacher,
          students : res.data.videoList.students,
          unmanned : studys,
          classify: res.data.lessonInfo.roomModel
        }
        this.allTime=res.data.videoList.teacher.video_duration
        this.totalTime=formatDurationTime(this.allTime)
        this.request('api/site/replay/monitors',{methods:"get",params:{
          task_id:jsons.task_id
        }}).then(opt=>{
          this.loading=false
          this.$store.commit("setinfors",jsonps);
          this.jsons=jsonps
          this.startTime=res.data.lessonInfo.microStartAt
          this.monitors=opt.data.monitors
        })
      })
    },
    startplay(){
      if(typeof(this.allTime)=='number' &&this.allTime<=0){
        this.$store.commit("setMaskshow","您的设备不支持当前视频的播放，请切换设备后重试");
        return
      }
      if(isNaN(parseInt(this.allTime))){
        this.$store.commit("setMaskshow","当前网络出现问题了，请刷新后重试或稍后重试");
        return
      }
      this.$store.commit("setStartPlays",true);
    },
    setTimese(time){
      var startTime=this.startTime
      var monitors=this.monitors
      this.playTime=formatDurationTime(time)
      this.proportion=formatFigureTime(this.allTime,time)
      if((startTime+time)==monitors[ins].onTimeStamp){
        this.swipers(ins)
      }
    },
    getclickTime(time){// 设置播放的时长与当前时间
      if(time>=this.allTime){
        return
      }
      var ns=ins
      for(var i=ns;i<this.monitors.length;i++){
        if(time >= this.monitors[i].operationMethod){
          this.swipers(i)
        }
      }
      this.$store.commit("setClickTime",time);
      this.playTime=formatDurationTime(time)
      this.proportion=formatFigureTime(this.allTime,time)
    },
    swipers(ins){
      var monitors=this.monitors
      var that=this
      switch(monitors[ins].operationMethod){
        case 'teacherOpenBoard':
          that.$store.commit("setBroadView")
          break;
        case 'teacherCloseBoard':
          that.$store.commit("setBroadView")
          break;
        case 'emojiAnimation':
          that.$store.commit("setExpression",monitors[ins].methodParams.emojis);
          break;
        case 'auxiliaryMainScreenSwitch':
          that.$store.commit("setPitchStudent",monitors[ins].methodParams.who);
          break;
        default:
      }
      ins=ins+1
    },
    kuaijin(){//监听点击播放下一秒
      var time=((this.$store.state.currTime+10).toFixed(0))*1000
      this.getclickTime(time)
    },
    changTime(numse){//点击播放的进度条获取时间
      var total=(numse/(this.$refs.appds.offsetWidth-30)).toFixed(2)
      var time=(total* this.allTime/1000).toFixed(0)*1000
      this.getclickTime(time)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #C0BDDC;
}
.starPlay{
  position: fixed;top: 0;bottom: 0;
  z-index: 9999;background: rgba(0, 0, 0, 0.4);
}
.starPlay_imgs{
  height: 6vh;
}
.centes{
  width: 100%;
  height: 100vh;
  padding: 5vh 0 5.1vh;
  position:relative;
}
.flex{
  display: flex;
}
.flex_alicen{
  align-items: center;
}
.flex_aliend{
  align-items: flex-end;
}
.flex_juscen{
  justify-content: center;
}
.flex_juspabt{
  justify-content: space-between;
}

.flex_cen_cen{
  display: flex;align-items: center;justify-content: center;
}

// video {
//     object-fit: fill;
//     object-position: center;
//  }
</style>
