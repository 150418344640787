import Vue from "vue";
import Vuex from 'vuex'

Vue.use(Vuex)
var setTimes=null

export default new Vuex.Store({
    state:{
        screen:false, //判断是横屏还是竖屏
        openWrite: false,//是否开启白板
        pitchStudent: 4,//当前展示的是那个学生 4为老师
        keys:"",//展示那个表情动画
        broad: {},//白板视频信息
        master: {},//主讲老师信息
        students: [],//学生列表信息
        unmanned:[],//有几个不满足的学生列表
        classify:0,//当前模式的分类
        changeTime:0,//播放时间的快进
        speed:1,//播放时间的倍速
        startPlays:false,//是否开始播放
        currTime:0,//获取点击的时长
        maskshow:'',//显示弹窗
        addTimes:0,//每次点击 学生的播放时间新增1s
        delayTime:0
    },
    mutations:{
        setScreen(state,data){//判断是横屏还是竖屏
            state.screen = data
        },
        setBroadView(state){//是否开启白板  点击打开白板和关闭白板
            state.openWrite = !state.openWrite
        },
        setPitchStudent(state,data){//关闭白板  点击老师或者学生
            state.pitchStudent = data
            state.openWrite = false
        },
        setExpression(state,data){// 展示那个动画
            state.keys = data
            clearTimeout(setTimes)
            setTimes=setTimeout(()=>{
                state.keys = ''
                clearTimeout(setTimes)
            },3000)
        },
        setinfors(state,data){ //配置老师 白板 学生 教师内容的信息
            state.broad = data.broad
            state.master = data.master
            state.students = data.students
            state.unmanned = data.unmanned
            state.classify = data.classify
        },
        setCurrcTime(state,data){ //点击快进到那个时间点
            state.currTime=data
        },
        setClickTime(state,data){ // 每次点击快进100s
            state.changeTime=data
        },
        setSpeed(state,data){ //  点击倍速播放
            state.speed=data
        },
        setStartPlays(state,data){ // 点击播放与停止播放
            state.startPlays=data
        },
        setMaskshow(state,data){ // 给弹窗显示信息
            state.maskshow=data
        },
        setplayTime(state,data){ //每次点击 学生的播放时间延迟播放时间变化 1s
            state.delayTime=state.delayTime-data*2000
        },
    }
})
