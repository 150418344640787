<template>
  <div class="main">
    <div class="main_topse" :class="{main_quan:classify==1}">
      <Teachers v-if="classify==1" />
      <BroadView class="baobeo" />
    </div>
    <BottomList  class="main_bottom" v-if="classify!=1" />
  </div>
</template>

<script>
import BroadView from "@/components/Players/BroadView/BroadView";
import BottomList from "@/components/Layout/BottomList";
import Teachers from "@/components/Layout/Teachers";
import { mapState } from "vuex";
export default {
  components: {
    BroadView,
    // Slider,
    BottomList,
    Teachers
  },
  computed:{
    ...mapState({
      classify:state => state.classify,
    })
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.main{
  display: flex;flex-direction: column;
  justify-content: space-between;width: 100%;height: 100%;
  position: relative;z-index: 60;
}
.main_bottom{
  height: 19vh;width: 100%;
}
</style>