<template>
  <div class="footer flex flex_alicen flex_juspabt">
    <div class="flex_cen_cen footersitems">
      <div class="footer_item borders flex_cen_cen">
        <img class="item_imags" src="../../assets/tab/forbidden.png" >
      </div>
      <div class="footer_item flex_cen_cen">
        <img class="item_imags" src="../../assets/tab/end.png" >
      </div>
    </div>
    <div class="footer_center">
      <img class="item_imags" src="../../assets/tab/teaching.png" >
    </div>
    <div class="flex_cen_cen footersitems">
      <div class="footer_item borders flex_cen_cen">
        <img class="item_imags" src="../../assets/tab/expression.png" >
      </div>
      <div class="footer_item flex_cen_cen">
        <img class="item_imags" src="../../assets/tab/setUp.png" >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;bottom: 0;z-index: 10;
  background: #d6d2da;
}
.footersitems{
  width: 40%;
  height: 100%;
}
.footer_item{
  width: 50%;
  height: 100%;
}
.borders{
  border-right: 1px solid #b7b7b7;
}
.footer_item:nth-last-child(1){
  border: none;
}
.footer_center{
  position: absolute;top: -20%;
  left: 50%;transform:translateX(-50%);
  bottom: 0;
}


.item_imags{
    height: 100%;
}
</style>