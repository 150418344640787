import axios from "axios";
import { Message } from 'element-ui'

const service = axios.create({
    baseURL:process.env.VUE_APP_API,
})

service.interceptors.request.use(config=>{
    config.headers= {
      'Content-Type': 'multipart/form-data',
    }
    return config
},error=>{
    Promise.reject(error)
})
service.interceptors.response.use(response=>{
    return response
},error=>{
    if(error && error.response){
        switch(error.response.status){
            case 400:
                error.message=''
                break;
            default:
                error.message=''
        }
    }else{
        if(JSON.stringify(error).includes('timeout')){
            error.message=''
        }else{
            error.message=''
        }
    }
    Message.error(error.message)
    return Promise.resolve(error.response)
})

export function request(url,option){
	return new Promise((resolve, reject) => {
        let {methods,data,params}=option
		service({
            url: url,
            method: methods||'post',
            data:option.data||{},
            params,
        }).then(res => {
            resolve(res.data)
        })
        .catch(err => {
            reject(err);
        })
	})
}