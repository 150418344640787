<template>
  <div class="baies">
    <!-- 侧边学生的分数 -->
    <div class="item_lefts"  v-if="!openWrite && pitchStudent!=nums">
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../../assets/icons/icons1.png" alt="" srcset="">
          <div>15</div>
        </div>
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../../assets/icons/icons2.png" alt="" srcset="">
          <div>15</div>
        </div>
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../../assets/icons/icons3.png" alt="" srcset="">
          <div>15</div>
        </div>
        <div class="lefts_icons">
          <img class="lefts_icons_image" src="../../../assets/icons/icons4.png" alt="" srcset="">
          <div>15</div>
        </div>
      </div>
      <!-- 两个背景图  加一个打开的按钮-->
    <div class="beijigs" v-if="!openWrite">
      <!-- <img class="beijings_imgs" src="../../../assets/floatingLayer.png" alt=""> -->
      <!-- <img class="beijings_imgs" src="../../../assets/floatingLayer2.png" alt=""> -->
      <div :class="{points:classify!=1,points1:classify==1}" @click.stop="setchange">
        <img class="points_image" src="../../../assets/expandWhiteboard.png" alt="" srcset="">
      </div>
    </div>

    <!-- 白板 -->
    <div class="broad" :class="{'broad_open':openWrite}">
      <div class="broad_lefts flex">
        <!-- 老师头像下的那些按钮功能 -->
        <div class="broad_lefts_btns">
          <div class="kongs"></div>
          <div v-if="classify==1" class="kongs kongs2"></div>
          <div :class="{twomenu:classify==1}" class="menu backage flex">
            <div class="menulefts">
              <div class="btnjiantou" @click="setchange">
                <img class="points_image" src="../../../assets/expandWhiteboard.png" alt="" srcset="">
              </div>
              <div>
                <div class="menulefts_items" v-for="item in number" :key="item">{{item}}</div>
              </div>
            </div>
            <div class="menulist">
              <div >
                <div class="menulist_item">
                  <img class="icosns" src="../../../assets/music.png" alt="" srcset="">
                </div>
                <div class="menulist_item">
                  <img class="icosns" src="../../../assets/search.png" alt="" srcset="">
                </div>
              </div>
              <div class="types">
                <div v-for="item in icons" :key="item.urls" class="flex flex_alicen types_items">
                  <img class="icosns1" :src="item.urls" alt="" srcset="">
                  <img class="icosns_points" src="../../../assets/point.png" alt="" srcset="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 展示的白板内容 -->
        <div class="boardse backage">
          <div class="boardse_centers flex_cen_cen">
            <VideoView :ids="'boards'" :items='broad' :src="broad.path" :viewSize="{ width: '180%' }" />
          </div>
        </div>
      </div>
      <!-- 课程的内容  播放的开始/暂停与结束 播放的课程包 -->
      <div v-if="false" class="kongjian backage flex flex_alicen flex_juspabt">
        <div class="flex flex_alicen">
          <div class="flex flex_alicen">
            <img class="kongjian_image" src="../../../assets/videos/last.png" alt="" srcset="">
            <img class="kongjian_plays kongjian_image" src="../../../assets/videos/playz.png" alt="" srcset="">
            <img class="kongjian_image" src="../../../assets/videos/next.png" alt="" srcset="">
          </div>
          <div class="kongjian_texts">音基乐理课中学生</div>
          <img class="kongjian_plays kongjian_image" src="../../../assets/videos/magnify.png" alt="" srcset="">
        </div>
        <div class="kongjian_names">音基乐理课中学生课包01.012</div>
        <div class="flex flex_alicen">
          <div>音基乐理课中学生课包</div>
          <img class="" src="../../../assets/videos/nextBtn.png" alt="" srcset="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoView from "../VideoView";
import { mapState } from "vuex";
export default {
  name: "BroadView",
  components: {
    VideoView,
  },
  computed:{
    ...mapState({
      pitchStudent:state => state.pitchStudent,
      openWrite:state => state.openWrite,
      broad:state => state.broad,
      classify:state => state.classify
    })
  },
  data() {
    return {
      nums:4,
      number:[1,2,3,4,5,6],
      icons:[
        {name:"",urls:require("../../../assets/homes.png")},
        {name:"",urls:require("../../../assets/qianbis.png")},
        {name:"",urls:require("../../../assets/backs.png")},
        {name:"",urls:require("../../../assets/cotype.png")},
      ]
    };
  },
  created() {},
  methods: {
    setchange(){//关闭白板
      this.$store.commit("setBroadView");
    }
  },
};
</script>

<style lang="scss" scoped>

.item_lefts{
  width: 5%;
  position: absolute;
  left: 10%;bottom: 8%;
  z-index: 900;
}
.lefts_icons_image{
  width: 80%;
}

.baies{
  position: absolute;top: 0;left: 0;right: 0;bottom: 0;
}
.beijigs{
  width: 100%;height: 100%;overflow: hidden;
  position: relative;
}

.beijings_imgs{
  position:absolute;top: 0;left: 0;
  width: 100%;height: 100%;z-index: 100;
}
.points{
    position: absolute;top: 19.5vh;right: 2vw;
    z-index: 999;
}
.points1{
    position: absolute;top: 40vh;right: 2vw;
    z-index: 999;
}

.broad{
  position: absolute;
  width: 100%;
  top: 0;bottom: 0;left: 100%;
  transition: 0.5s left;
}
.broad_open{
  left: 0;
}
.broad_lefts{
  position: relative;
  height: 100%;
}
.broad_lefts_btns{
  margin: 0 5px;
}
.kongs{
  height: 19vh;
}
.kongs2{
  margin: 0 0 5px;
}
.menu{
  height: calc(100% - 19vh);border-radius: 4px;
}
.twomenu{
  height: calc(100% - 38vh - 10px);
}
.menulefts{
  padding: 15px 0;text-align: center;
}
.menulefts_items{
  margin-top: 3.6vh;font-size: 1.6vh;
}
.btnjiantou img{
  margin: 10% 0 15%;
  transform:rotate(180deg);
}
.icosns{
  width: 3vh;
}
.icosns1{
  width: 2.5vh;
}

.menulist{
  margin-left: 2.6vh;
  display: flex;flex-direction: column;
  justify-content: space-between;
}
.menulist_item{
  margin-top: 2.6vh;
}

.icosns_points{
  width: 1vh;margin-left: 2.6vh;
}
.types_items{
  margin-bottom: 4vh;
}

.boardse{
  height: 100%;
  border-radius: 6px;
  padding: 10px;
}
.boardse_centers{
  width: 100%;height: 100%;
  overflow: hidden;
}



.kongjian{
  height: 5.3vh;border-radius: 4px;overflow: hidden;
  margin: 10px 5px 0;padding: 0 10px;
  font-size: 1.4vh;
}
.kongjian_image{
  height: 2.6vh;
}
.kongjian_plays{
  margin: 0 12px;
}
.kongjian_texts{
  margin: 0 20px;
}
.kongjian_names{
  background: #7851B1;color: #fff;
  padding: 4px 1.4vh;
  border-radius: 30px 30px 30px 0;
}
</style>