<template>
<div class="progress"  @mouseleave="leavase">
      <!-- 进度条的背景  进度条拖到 -->
      <div class="progress_mains" @mousemove="moves" @mouseup="moveups" >
          <div class="progress_rights"  ref="proports" @click="getClick($event)">
              <div :style="{width: tag?progress:proportion+'%'}" class="playTime"></div>
              <div @mousedown="downs"  :style="{left: tag?progress:proportion+'%'}" class="progress_btn"></div>
          </div>
          <div class="progress_lefts">
              <!-- <img class="progress_imgs" src="../../assets/videos/playz.png" alt="" srcset=""> -->
              <!-- 快进按钮 点击快进1s -->
              <img @click="kuaijin" class="progress_imgs" src="../../assets/videos/next.png" alt="" srcset="">
              <!-- 倍速按钮  1倍 2倍 3倍 -->
              <div class="spees">
                <div @click="showlist" class="spees_cruuser">{{speed}}.0X</div>
                <div class="spees_abase">
                  <div class="spees_abase_centers">
                    <div @click="closeShowList(1)" class="spees_abase_items">1.0X</div>
                    <div @click="closeShowList(2)" class="spees_abase_items">2.0X</div>
                    <div @click="closeShowList(3)" class="spees_abase_items">3.0X</div>
                  </div>
                </div>
              </div>
              <!-- 当前播放的时间/ 当前课程的总时间 -->
              <div class="schedule">
                  <span class="currteTime">{{playTime}}</span>
                  <span>/</span>
                  <span class="totalTime">{{totalTime}}</span>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    totalTime:{//这堂课的总时间
      type:String,
      default: () => '00:00',
    },
    playTime:{//当前播放的时间
      type:String,
      default: () => '00:00',
    },
    proportion:{//当前时间占进度条的百分比
      type:String,
      default: () => '0',
    },
  },
  computed:{
    ...mapState({
      speed:state => state.speed,//当前的倍速
    })
  },
  data() {
    return {
      ishsowList: false,//是否展示显示倍速的模块内容
      progress:0,// 在进度条拖动时 开始的位置
      tag:false,// 用来判断当前拖动是否在播放的进度条上
    };
  },
  methods:{
    // 监听点击在进度条
    downs(e){
      this.tag=true
    },
    // 监听在进度条的拖动
    moves(e){
      if (this.tag&&e.offsetX!=15) {
        this.progress = e.offsetX+"px";
      }
    },
    // 监听拖动的手指抬起
    moveups(e){
      if (this.tag) {
        this.progress = e.toElement.offsetLeft+"px";
        this.$emit('changTime',e.offsetX)
      }
      this.tag=false
    },
    // 监听离开进度条
    leavase(e){
      this.tag=false
    },
    // 点击快进按钮 
      kuaijin(){
          this.$emit('kuaijin')
      },
      // 直接点击播放进度条的某个时间点
      getClick(even){
          this.$emit('changTime',even.offsetX)
      },
      // 点击开始选择倍速
      showlist(){
          this.ishsowList=true
      },
      // 选择想要的倍速 关闭选择倍速的模块
      closeShowList(ns){
        this.ishsowList=false
        this.$store.commit("setSpeed",ns);
      }
  }
}
</script>

<style>

.progress{
    position: fixed;
    bottom: 0;
    margin: 0 auto;z-index: 800;
    display: flex;align-items: flex-end;
}
.progress_mains{
    position: relative;z-index: 9999;
    width: 100%;background: rgba(0, 0, 0, 0.6);padding-top: 20px;
}
.progress_rights{
    width: calc(100% - 30px);height: 4px;background: #ccc;position: relative;
    margin: 0 15px;
}
.playTime{
    position: absolute;top: 0;left: 0;bottom: 0;
    background: #00A13B;
}
.progress_btn{
  width: 10px; height: 10px;padding:5px;
  border:5px solid #fff;
  border-radius: 50%; 
  position: absolute;
  transform:translate(-50%,-50%);
  background:#00A13B;
  top: 2px;
  left: 0px; cursor: pointer;}
/* .progress_btn:hover{border-color:#F7B824;} */
.progress_lefts{
    display: flex;align-items: center;
    padding: 10px 0;
}
.progress_imgs{
    height: 2.6vh;margin-left: 25px;
}
.schedule{
    color: #ccc;margin-left: 35px;
    font-size: 1vh;
}
.currteTime{
    color: #fff;
}

.spees{
    position: relative;
    margin-left: 30px;
    color: #fff;
}
.spees_cruuser{
    cursor: pointer;
}
.spees_abase{
  display: none;
  position: absolute;
  bottom: 0;left: -20px;padding-bottom: 2.6vh;
}
.spees_abase_centers{
  background: rgba(0, 0, 0, 0.6);
  transform: translateY(-35px);
}
.spees_abase_items{
    cursor: pointer;
    padding: 10px 20px;
}
.spees:hover .spees_abase{
  display: block;
}



</style>